import { useState, useEffect } from "react";
import { getRemainingTimeUntilMsTimeStamp } from "./Utils/CountDowntimerUtills";
import { Col, Row } from "react-bootstrap";

const defaultRemainingTime = {
  seconds: "00",
  minutes: "00",
  hours: "00",
  days: "00",
};

const CountdownTimer = ({ countdownTimestampMs }) => {
  const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateRemainingTime(countdownTimestampMs);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [countdownTimestampMs]);

  function updateRemainingTime(countdown) {
    setRemainingTime(getRemainingTimeUntilMsTimeStamp(countdown));
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className="countdown-timer">
          <Row>
            <Col>
              <Row>
                <p>{remainingTime.days}</p>
                <span>Días</span>
              </Row>
            </Col>

            <Col>
              <Row>
                <p>{remainingTime.hours}</p>
                <span>Horas</span>
              </Row>
            </Col>

            <Col>
              <Row>
                <p>{remainingTime.minutes}</p>
                <span>Minutos</span>
              </Row>
            </Col>

            <Col>
              <Row>
                <p>{remainingTime.seconds}</p>
                <span>Segundos</span>
              </Row>
            </Col>
          </Row>
        </div>

      </header>
    </div>
  );
};

export default CountdownTimer;
