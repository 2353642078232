import "./App.css";
import { ImageBackground, StyleSheet } from "react-native";
import image3 from "./Images/YDF481.jpg";
import React, { useState } from "react";
import VerificationForm from "./Components/verificationForm";
import Informacion from "./Components/Informacion";
import Menu from "./Components/Menu";
import { Button } from "react-bootstrap";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  image: {
    flex: 1,
    width: null,
    height: null,
  },
});
// imageStyle={{ opacity: 0.5 }}

function App() {
  const [guest, setGuest] = useState(null);
  const [guestCode, setGuestguestCode] = useState(null);

  const getVerificationResult = (dataResult) => {
    if(dataResult === 1){
      setGuest(dataResult);
    }
    else{
      if(dataResult !== null){
        setGuest(dataResult.attributes);
          setGuestguestCode(dataResult.id)
      }
    }
   
  };

  return (
    <ImageBackground source={image3} resizeMode="cover" style={styles.image}>
       {guest === null ? (
        <header className="Header-Verification">
            <VerificationForm onSubmit={getVerificationResult} />
           
        </header>
      ) : guest === 1? <Informacion></Informacion> : (<Menu guest={guest} guestCode={guestCode}/>)
      }
    </ImageBackground>
  );
}

export default App;
