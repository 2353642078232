import {Card, Row} from 'react-bootstrap';

const InformationCard = ({title, textDetails, image, buttonText, link, opacityCard,buttonText2, link2}) => {
  return (
    <Card  bg="light"
    text="dark"
    className="mb-2"
    border="white"
    style={{ width: '20rem', margin:'5px', opacity:opacityCard, alignItems:'center',justifyContent:'center' }}>
        <Card.Img variant="top" src={image} />
        <Card.Body className='text-center'>
        <Card.Title>{title}</Card.Title>
        <Card.Text style={{fontSize:'18px'}}>
          {textDetails}
        </Card.Text>
        <Row>

        <Card.Link  target='blank' href={link}>{buttonText}</Card.Link>
        </Row>
        <Row>
        {
          link2 === ''? null: <Card.Link className="text-muted fs-6"  target='blank' href={link2}>{buttonText2}</Card.Link>
        }

        </Row>
      </Card.Body>
    </Card>
  );
}

export default InformationCard;