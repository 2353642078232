import "./../App.css";
import React, { useState } from "react";
import Parse from "parse/dist/parse.min.js";
import InformationCard from "./InformationCard";
import wedShoots from "../Images/Wedshoots.jpg";
import {
  ListGroup,
  ListGroupItem,
  Container,
  Form,
  Row,
  Alert,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Accordion from "react-bootstrap/Accordion";

const Informacion = () => {
  return (
    <div className="flexbox-container">
    <header className="App-header2">
        <Container fluid="md" style={{ fontFamily:'sans-serif', fontWeight: "bold", marginTop: 10 }}>
          <Accordion defaultActiveKey={["0"]} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Nieves y paletas</Accordion.Header>
              <Accordion.Body>
                <Accordion defaultActiveKey={["0"]} alwaysOpen>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Sabores de paletas</Accordion.Header>
                    <Accordion.Body>
                    <ListGroup>
                    <ListGroup.Item  action variant="info">Pistache </ListGroup.Item>
                    <ListGroup.Item>
                      Coco
                    </ListGroup.Item>
                    <ListGroup.Item action variant="info">
                      Chocolate abuelita
                    </ListGroup.Item>
                    <ListGroup.Item>
                      Mango tajin
                    </ListGroup.Item>
                    <ListGroup.Item  action variant="info">
                      Pelon pelo rico
                    </ListGroup.Item>
                  </ListGroup>
                    </Accordion.Body>
                  </Accordion.Item>
                
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Sabores de nieves</Accordion.Header>
                    <Accordion.Body>
                      <ListGroup>
                        <ListGroup.Item >
                          Queso durazno
                        </ListGroup.Item>
                        <ListGroup.Item  action variant="info">
                          Chocolate abuelita
                        </ListGroup.Item>
                        <ListGroup.Item>
                          Pica fresa
                        </ListGroup.Item>
                        <ListGroup.Item  action variant="info">
                          Vino
                        </ListGroup.Item>
                        <ListGroup.Item >
                          Tequila
                        </ListGroup.Item>
                      </ListGroup>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Candy bar</Accordion.Header>
              <Accordion.Body>
                <ListGroup>
                  <ListGroup.Item> Alfajores</ListGroup.Item>
                  <ListGroup.Item  action variant="info"> choco obleas</ListGroup.Item>
                  <ListGroup.Item> Macarones</ListGroup.Item>
                  <ListGroup.Item  action variant="info"> Minibrownie</ListGroup.Item>
                  <ListGroup.Item> Minimostachón durazno</ListGroup.Item>
                  <ListGroup.Item action variant="info"> Minimostachón Ferrero</ListGroup.Item>
                  <ListGroup.Item > mousse chocolate shot</ListGroup.Item>
                  <ListGroup.Item  action variant="info"> Pie de limón shot</ListGroup.Item>
                  <ListGroup.Item> Polvorones nuez</ListGroup.Item>
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="6">
              <Accordion.Header>
                Compartenos tus fotos con la siguiente App
              </Accordion.Header>
              <Accordion.Body>
                <InformationCard
                  title=""
                  textDetails={
                    <div>
                      Compártenos tus fotos
                      <br />
                      Código del álbum: MX45ef0e27
                    </div>
                  }
                  image={wedShoots}
                  opacityCard={0.8}
                  buttonText="Descargar Android"
                  link="https://app.appsflyer.com/net.bodas.android.wedshoots?pid=WP-Android-ES&c=WP-ES-LANDINGS"
                />
                <InformationCard
                  title=""
                  textDetails={
                    <div>
                      Compártenos tus fotos
                      <br />
                      Código del álbum: MX45ef0e27
                    </div>
                  }
                  image={wedShoots}
                  opacityCard={0.8}
                  buttonText="Descargar iOS"
                  link="https://app.appsflyer.com/id660256196?pid=WP-iOS-ES&c=WP-ES-LANDINGS&s=es"
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </header>
    </div>
  );
};

export default Informacion;
