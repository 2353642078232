import React, { useState } from "react";
import { Nav, Navbar, Col, CardGroup } from "react-bootstrap";
import ConfirmationForm from "./ConfirmationForm";
import InformationCard from "./InformationCard";
import { ImageBackground, StyleSheet } from "react-native";
import ImgBackground from "./../Images/YDF481.jpg";
import CountDown from "./CountdownTimer";
import Confirmados from "./Confirmados";
import PaseDigital from "./PaseDigital";
import iglesiaImg from "./../Images/Iglesia.png";
import terrazaCibeles from "./../Images/TerrazaCibeles.jpg";
import walmartLogo from "./../Images/WalmartLogo.jpg";
import amazonLogo from "./../Images/AmazonLogo2.png";
import liverpoolLogo from "./../Images/LiverpoolLogo.png";
import spotifyLogo from "./../Images/SpotifyLogo.png";
import icon from "./../Images/LogoNegro.png";
import "bootstrap/dist/css/bootstrap.min.css";
import wedShoots from "../Images/Wedshoots.jpg";
import Gallery from "./Gallery";
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  image: {
    flex: 1,
    width: null,
    height: null,
  },
});

const Menu = ({ guest, guestCode }) => {
  const [menuSelection, setMenuSelection] = useState(guest.EsAdmin === true ? 6 : guest.Confirmado === true? guest.PaseDigital === true? 7 : 0 : 1);
   
  const handleSelect = (eventKey) => setMenuSelection(Number(eventKey));
  const viewSelection = () => 
  {
    switch(menuSelection){
      case 0:
        return <CountDown countdownTimestampMs={1695423600000}/>;
        case 1: 
        return <ConfirmationForm guest={guest} guestCode={guestCode} />;
        case 2:
          return <Gallery guest={guest} guestCode={guestCode}/>;
        case 3:
        return <div className="flexbox-container">
        <header className="App-header">
        <CardGroup>
        <Col>
        <InformationCard title="Iglesia" opacityCard={.8} textDetails="Sagrada familia. Venustiano Carranza 93, Salvarcar, 32575 Cd Juárez, Chih." image={iglesiaImg} buttonText="Abrir ubicación" link="https://goo.gl/maps/QtoKeBeoQcaLN9wp7"/>
        </Col>
        <Col>
        <InformationCard title="Jardín" opacityCard={.8} textDetails="Terraza Jardín Cibeles Bulevar Tomás Fernández 8450, Partido Senecú, 32540 Cd Juárez, Chih." image={terrazaCibeles} buttonText="Abrir ubicación" link="https://goo.gl/maps/Lk9GH1cLmoq92YVFA"/>
        </Col>
        </CardGroup>
      </header>
      </div>;
          case 4:
            return <div className="flexbox-container">
            <header className="App-header">
            <CardGroup>
           <Col>
            <InformationCard title="" link2="https://mesaderegalos.liverpool.com.mx/milistaderegalos/51169687" buttonText2={<br/>}  textDetails={<div>Número de evento: <b>51169687</b></div>} opacityCard={.8} image={liverpoolLogo} buttonText="Ir a mesa de regalo" link="https://mesaderegalos.liverpool.com.mx/milistaderegalos/51169687"/>
            </Col>
            <Col>
            <InformationCard title="" link2="https://www.amazon.com.mx/wedding/share/Ana_arturo22Sep2023" buttonText2={<br/>} textDetails={<div><br/></div>} image={amazonLogo} opacityCard={.8} buttonText="Ir a mesa de regalo" link="https://www.amazon.com.mx/wedding/share/Ana_arturo22Sep2023"/>
            </Col>
            <Col>
            <InformationCard title="" textDetails={<div>Para abrir desde México, abre primero el link "Abrir desde México" y después de abrir, da clic en el link normal.</div>} link2="https://www.walmart.com/?country=US" buttonText2="Abrir desde México"  opacityCard={.8} image={walmartLogo} buttonText="Ir a mesa de regalo" link="https://www.walmart.com/registry/WR/86899a64-27dc-42b8-8e9f-a38f25b64f98"/>
            </Col>
          </CardGroup>
          </header>
          </div>;
          case 5:
            return <div className="flexbox-container">
            <header className="App-header">
            <CardGroup>
             <InformationCard title="" textDetails={<div>¿Qué canciones te gustaría escuchar?<br/></div>} image={spotifyLogo} opacityCard={.8} buttonText="Sugerir música" link="https://open.spotify.com/playlist/6AcO0TFyMGm1U0GteLEohY?si=cdd01111ebc04528&pt=16f386a3d6414a0b88abc71573928f6a"/>
             <InformationCard title="" textDetails={<div>Compártenos tus fotos<br/>Código del álbum: MX45ef0e27</div>} image={wedShoots} opacityCard={.8} buttonText="Descargar iOS" link="https://app.appsflyer.com/id660256196?pid=WP-iOS-ES&c=WP-ES-LANDINGS&s=es" buttonText2="Descargar Android" link2="https://app.appsflyer.com/net.bodas.android.wedshoots?pid=WP-Android-ES&c=WP-ES-LANDINGS"/>
            </CardGroup>
             </header>
          </div>;
          case 6:
            return <Confirmados/>;
            case 7: 
            return <PaseDigital guest={guest} guestCode={guestCode}/>;
      default:
        return null;
    }
  };

  return (
    <div className="flexbox-container">
      <header className="App-header2">
      <ImageBackground source={ImgBackground} resizeMode="cover" style={styles.image}>
          <Navbar bg="light" expand="lg">
            <Navbar.Brand href="#home">
             
              <img src={icon}  width="30" style={{marginLeft:10}}
              className="d-inline-block align-top"
              height="30" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav ">
              <Nav className="me-auto " onSelect={handleSelect} >
                <Nav.Link eventKey="0" href="#home">Inicio</Nav.Link>
                {guest.Confirmado === false ? <Nav.Link eventKey="1" href="#link">Confirmación de asistencia</Nav.Link> : null}
                <Nav.Link eventKey="2" href="#link">Galería</Nav.Link>
                <Nav.Link eventKey="3" href="#link">Direcciones</Nav.Link>
                <Nav.Link eventKey="4" href="#link">Mesas de regalo</Nav.Link>
                <Nav.Link eventKey="5" href="#link">Extras</Nav.Link>
                {guest.EsAdmin === true ? <Nav.Link eventKey="6" href="#link">Admin</Nav.Link> : null}
                {guest.PaseDigital === true ? <Nav.Link eventKey="7" href="#link">Pase dígital</Nav.Link> : null}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          { viewSelection()}
      </ImageBackground>
        </header>
    </div>
  );
};

export default Menu;
