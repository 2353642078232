import image1 from "./../Images/YDF296.jpg";
import image6 from "./../Images/YDF318.jpg";
import image5 from "./../Images/YDF483.jpg";
import image4 from "./../Images/YDF275.jpg";
import image3 from "./../Images/YDF372.jpg";
import image2 from "./../Images/YDF132.jpg";

import videoBoda from "../Images/Video.mp4";
import React, { useState } from "react";
import './../Gallery.css'

const Gallery = ({ guest , guestCode}) => {

  let data =  [
    {
      id: 0,
      imgSource : videoBoda,
      esVideo : true
    },
    {
      id: 1,
      imgSource : image1,
      esVideo : false

    },
    {
      id: 2,
      imgSource : image2,
      esVideo : false
    },
    {
      id: 3,
      imgSource : image3,
      esVideo : false
    },
    {
      id: 4,
      imgSource : image4,
      esVideo : false
    },
    {
      id: 5,
      imgSource : image5,
      esVideo : false
    },
    {
      id: 6,
      imgSource : image6,
      esVideo : false
    }
  ]
const [model, setModel] = useState(false);
const [temImgSrc, setTempImgsrc] = useState('');
  const getImg = (imgSource) => {
setTempImgsrc(imgSource);
setModel(true);
  }

  return (
<>
<div className={model? "model open" : "model"}>
<img src={temImgSrc} onClick={()=>setModel(false)}></img>
</div>
<div className='gallery'>
      {data.map((item, index) => 
      {
        return(
            item.esVideo === false? 
              <div className='pics' key={index}>
                <img src={item.imgSource} style={{width: '100%' }} onClick={()=> getImg(item.imgSource)}></img>
                 </div>:
                  <video controls style={{width: '100%' }}>
                  <source src={item.imgSource} 
          type="video/mp4"></source>
                  Sorry, your browser doesn't support videos.
              </video>
      )})}
   </div>
   </>
  );
};

export default Gallery;
