import React, { useState } from "react";
import { Card, Form, Button, Container, Row, Col } from "react-bootstrap";
import Parse from "parse/dist/parse.min.js";
import "bootstrap/dist/css/bootstrap.min.css";

const ConfirmationForm = ({ guest , guestCode}) => {
  const [Numinvitados, setNumInvitados] = useState(0);
  const [Asistire, setAsistire] = useState(true);
  const [EnabledConfirmar, setEnabledConfirmar] = useState(false);

  const handleChange = (event) => {
    setNumInvitados(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(Numinvitados <= 0 && Asistire === true){
      alert(
        `El número de invitados ingresado no es válido. Debe ser un número mayor a 0.`
      );
    }else{
      if (Numinvitados > guest.NumeroDeInvitados) {
        alert(
          `La número de invitados ingresado es mayor al que le fue asignado en su invitación.`
        );
      } else{
        const guest = new Parse.Object("Guest");
        guest.set("objectId", guestCode);
        guest.set("Confirmado", true);
        guest.set("Asistira", Asistire);
        guest.set("FechaDeConfirmacion", new Date());
        guest.set("NumeroDeInvitadosPorAsistir", Number(Numinvitados));
  
        try {
          setEnabledConfirmar(true);
           await guest.save();
          alert('Confirmación registrada!');
         
        } catch (error) {
          alert(error);
        }
        setEnabledConfirmar(false);
      }
    }
  };

  const handleChangeCheckBox = (evt) => {
    setAsistire(evt.target.checked);
  };

  return (
    <div className="App">
    <header className="App-header">
      <Container className="text-white fst-italic">
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Row>
            <Col sm={6}>
              <Card
              style={{opacity:.8}}
                bg="light"
                text="dark"
                border="white"
                className="mb-2"
              >
                <Card.Header>
                  Estimado/a: <b>{guest.ApellidosDeFamilia}</b>
                </Card.Header>
                <Card.Body>
                  <Card.Subtitle>
                    Para este día tan importante en nuestras vidas nos gustaría
                    que sean testigos del inicio de esta nueva etapa juntos.
                    <br />
                    <br />
                    Solicitamos de su ayuda confirmando su asistencia.
                    <br />
                    <br />
                    Cantidad de pases asignados:{" "}
                    <b>{guest.NumeroDeInvitados}</b>
                    <br />
                   
                    <br />
                  </Card.Subtitle>
                  <br />
                 
                      <Row  xs={3} md={4} lg={6}>
                        <Col>
                          <Form.Label style={{ marginLeft:'5px'}}>Asistiré</Form.Label>
                        </Col>
                        <Col>
                          <Form.Check
                           style={{ marginLeft:'15px'}}
                            type="switch"
                            label=""
                            onChange={(e) => handleChangeCheckBox(e)}
                            checked={Asistire}
                          />
                        </Col>
                      </Row>
                 
                 {
                    guest.MensajeNinos === true?  <Card.Text className="text-muted fs-6">
                    <br/>
                  "Niños menores de 12 años: dulces sueños. Adultos: busquen sus zapatos de baile"
                  <br/> 
                
                    </Card.Text>: null
                 }
                </Card.Body>
              </Card>
            </Col>
            <Col sm={6}>
              <br />
              <Row>
                
                  {Asistire === true ? (
                    <Form.Group>
                      <Form.Label>
                      Muchas gracias por acompañarnos, favor de especificar cuántos pases de los asignados necesitarás.
                      </Form.Label>
                      <Form.Control
                        type="input"
                        id="codeField"
                        placeholder={"Número de invitados"}
                        onChange={handleChange}
                      ></Form.Control>
                    </Form.Group>
                  ) : (
                    <div className="d-grid gap-2">
                      Lamentamos saber que no podrás acompañarnos. <br />
                      Muchas gracias por notificárnoslo.
                </div>
                  )}
              </Row>
              <br />
              <Row>
                <div className="d-grid gap-2">
                  <Button variant="outline-light" size="lg" type="submit" disabled={EnabledConfirmar} >
                    Confirmar
                  </Button>
                </div>
                <Row>
                <div  className="text-white fst-italic text-center">
                <p><a class="text-white bg-dark" href="https://anayarturoboda.com.mx/">Confirmar otro código</a></p>
</div>
                </Row>
              </Row>
            </Col>
          </Row>
        </Form>
      </Container>
      </header>
    </div>
  );
};

export default ConfirmationForm;
