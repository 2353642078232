import "./../App.css";
import React, { useState } from "react";
import Parse from "parse/dist/parse.min.js";
import { Button, Col, Container, Form, Row, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";

const app_id = process.env.REACT_APP_PARSE_ID;
const javaScriptKey = process.env.REACT_APP_PARSE_JAVASCRIPT_KEY;
const hostUrl = process.env.REACT_APP_PARSE_HOST_URL;

Parse.initialize(app_id, javaScriptKey);
Parse.serverURL = hostUrl;

const Confirmados = (props) => {
  const [invitadosQueAsistiranNovia, setAsistiranNovia] = useState([]);
  const [invitadosQueAsistiranNovio, setAsistiranNovio] = useState([]);
  const [TotalInvitacionesConfirmadas, setTotalInvitacionesConfirmadas] =
    useState(0);
  const [
    TotalInvitacionesConfirmadasNovia,
    setTotalInvitacionesConfirmadasNovia,
  ] = useState(0);
  const [
    TotalInvitacionesConfirmadasNovio,
    setTotalInvitacionesConfirmadasNovio,
  ] = useState(0);

  const [InvitadosSinConfirmarNovia, setInvitadosSinConfirmarNovia] = useState(
    []
  );
  const [InvitadosSinConfirmarNovio, setInvitadosSinConfirmarNovio] = useState(
    []
  );

  const [TotalInvitadosSinConfirmar, setTotalInvitadosSinConfirmar] =
    useState(0);
  const [TotalInvitadosSinConfirmarNovia, setTotalInvitadosSinConfirmarNovia] =
    useState(0);
  const [TotalInvitadosSinConfirmarNovio, setTotalInvitadosSinConfirmarNovio] =
    useState(0);
  const [TotalInvitacionesSinConfirmar, setTotalInvitacionesSinConfirmar] =
    useState(0);
  const [
    TotalInvitacionesSinConfirmarNovia,
    setTotalInvitacionesSinConfirmarNovia,
  ] = useState(0);
  const [
    TotalInvitacionesSinConfirmarNovio,
    setTotalInvitacionesSinConfirmarNovio,
  ] = useState(0);

  const [TotalinvitadosQueAsistiranSum, setTotalAsistiranSum] = useState(0);
  const [TotalinvitadosQueAsistiranSumNovia, setTotalAsistiranSumNovia] =
    useState(0);
  const [TotalinvitadosQueAsistiranSumNovio, setTotalAsistiranSumNovio] =
    useState(0);
  const [InvitadosQueNoAsistiranNovia, setNoAsistiranNovia] = useState([]);
  const [InvitadosQueNoAsistiranNovio, setNoAsistiranNovio] = useState([]);
  const [TotalinvitadosQueNoAsistiran, setTotalNoAsistiran] = useState(0);
  const [TotalinvitadosQueNoAsistiranNovia, setTotalNoAsistiranNovia] =
    useState(0);
  const [TotalinvitadosQueNoAsistiranNovio, setTotalNoAsistiranNovio] =
    useState(0);
  const [TotalinvitadosQueNoAsistiranSum, setTotalNoAsistiranSum] = useState(0);
  const [TotalinvitadosQueNoAsistiranSumNovia, setTotalNoAsistiranSumNovia] =
    useState(0);
  const [TotalinvitadosQueNoAsistiranSumNovio, setTotalNoAsistiranSumNovio] =
    useState(0);

  function add(accumulator, a) {
    return accumulator + a;
  }

  const GetRight = (number) => {
    const numberString = number.toString();
    return "0".repeat(3 - numberString.length) + numberString;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const query = new Parse.Query("Guest");
    query.limit(400);
    var asistiranNovio = new Array();
    var asistiranNovia = new Array();

    var noAsistiranNovio = new Array();
    var noAsistiranNovia = new Array();

    var sinConfirmarNovio = new Array();
    var sinConfirmarNovia = new Array();

    var totalinvitadosSinConfirmarNovia = 0;
    var ttlinvitadosSinConfirmarNovio = 0;
    var totalinvitadosSinConfirmar = 0;

    var totalAsistiran = 0;
    var totalAsistiranNovia = 0;
    var totalAsistiranNovio = 0;

    var totalNoAsistiran = 0;
    var totalNoAsistiranNovia = 0;
    var totalNoAsistiranNovio = 0;

    try {
      const queryResults = await query.find();

      if (queryResults.length > 0) {
        for (var i = 0; i < queryResults.length; i++) {
          if (queryResults[i].attributes.Confirmado === true) {
            if (queryResults[i].attributes.Asistira === true) {
              if (queryResults[i].attributes.RelacionCon === "Novia") {
                asistiranNovia.push(queryResults[i].attributes);
                totalAsistiranNovia = add(
                  totalAsistiranNovia,
                  queryResults[i].attributes.NumeroDeInvitadosPorAsistir
                );
              } else {
                totalAsistiranNovio = add(
                  totalAsistiranNovio,
                  queryResults[i].attributes.NumeroDeInvitadosPorAsistir
                );
                asistiranNovio.push(queryResults[i].attributes);
              }

              totalAsistiran = add(
                totalAsistiran,
                queryResults[i].attributes.NumeroDeInvitadosPorAsistir
              );
            } else {
              if (queryResults[i].attributes.RelacionCon === "Novia") {
                noAsistiranNovia.push(queryResults[i].attributes);
                totalNoAsistiranNovia = add(
                  totalNoAsistiranNovia,
                  queryResults[i].attributes.NumeroDeInvitados
                );
              } else {
                noAsistiranNovio.push(queryResults[i].attributes);
                totalNoAsistiranNovio = add(
                  totalNoAsistiranNovio,
                  queryResults[i].attributes.NumeroDeInvitados
                );
              }
              totalNoAsistiran = add(
                totalNoAsistiran,
                queryResults[i].attributes.NumeroDeInvitados
              );
            }
          } else {
            if (queryResults[i].attributes.RelacionCon === "Novia") {
              sinConfirmarNovia.push(queryResults[i].attributes);
              totalinvitadosSinConfirmarNovia = add(
                totalinvitadosSinConfirmarNovia,
                queryResults[i].attributes.NumeroDeInvitados
              );
            } else {
              sinConfirmarNovio.push(queryResults[i].attributes);
              ttlinvitadosSinConfirmarNovio = add(
                ttlinvitadosSinConfirmarNovio,
                queryResults[i].attributes.NumeroDeInvitados
              );
            }
            totalinvitadosSinConfirmar = add(
              totalinvitadosSinConfirmar,
              queryResults[i].attributes.NumeroDeInvitados
            );
          }
        }
        setAsistiranNovia(asistiranNovia);
        setAsistiranNovio(asistiranNovio);
        setTotalInvitacionesConfirmadasNovia(asistiranNovia.length);
        setTotalInvitacionesConfirmadasNovio(asistiranNovio.length);
        setTotalInvitacionesConfirmadas(
          asistiranNovia.length + asistiranNovio.length
        );
        setTotalAsistiranSumNovia(totalAsistiranNovia);
        setTotalAsistiranSumNovio(totalAsistiranNovio);
        setTotalAsistiranSum(totalAsistiran);

        setInvitadosSinConfirmarNovia(sinConfirmarNovia);
        setTotalInvitadosSinConfirmarNovia(totalinvitadosSinConfirmarNovia);
        setTotalInvitacionesSinConfirmarNovia(sinConfirmarNovia.length);

        setInvitadosSinConfirmarNovio(sinConfirmarNovio);
        setTotalInvitadosSinConfirmarNovio(ttlinvitadosSinConfirmarNovio);
        setTotalInvitacionesSinConfirmarNovio(sinConfirmarNovio.length);

        setTotalInvitadosSinConfirmar(totalinvitadosSinConfirmar);
        setTotalInvitacionesSinConfirmar(
          sinConfirmarNovia.length + sinConfirmarNovio.length
        );

        setNoAsistiranNovia(noAsistiranNovia);
        setTotalNoAsistiranNovia(noAsistiranNovia.length);
        setTotalNoAsistiranSumNovia(totalNoAsistiranNovia);

        setNoAsistiranNovio(noAsistiranNovio);
        setTotalNoAsistiranNovio(noAsistiranNovio.length);
        setTotalNoAsistiranSumNovio(totalNoAsistiranNovio);

        setTotalNoAsistiran(noAsistiranNovia.length + noAsistiranNovio.length);
        setTotalNoAsistiranSum(totalNoAsistiran);
      }
    } catch (error) {}
  };

  return (
    <div className="App">
      <header className="App-header">
        <Container fluid="md" style={{opacity:".7", fontWeight:"bold"}}>
          <Form onSubmit={(e) => handleSubmit(e)}>
            <Button variant="light" size="lg" type="submit">
              Realizar consulta
            </Button>
          </Form>

          <Accordion defaultActiveKey={["0"]} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Asistiran {TotalinvitadosQueAsistiranSum} personas (
                {TotalInvitacionesConfirmadas} invitaciones)
              </Accordion.Header>
              <Accordion.Body>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    Invitados novia: {TotalinvitadosQueAsistiranSumNovia}{" "}
                    personas ({TotalInvitacionesConfirmadasNovia} invitaciones)
                  </Accordion.Header>
                  <Accordion.Body>
                    <Table responsive style={{ fontSize: "12px" }}>
                      <thead>
                        <tr>
                          <th># Orden</th>
                          <th>Código</th>
                          <th>Nombre</th>
                          <th>Asistirán</th>
                        </tr>
                      </thead>
                      {invitadosQueAsistiranNovia.map((a) => (
                        <tbody key={a.Codigo}>
                          <td>{GetRight(a.Orden)}</td>
                          <td>{a.Codigo}</td>
                          <td>{a.ApellidosDeFamilia}</td>
                          <td>{a.NumeroDeInvitadosPorAsistir}</td>
                        </tbody>
                      ))}
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    Invitados novio: {TotalinvitadosQueAsistiranSumNovio}{" "}
                    personas ({TotalInvitacionesConfirmadasNovio} invitaciones)
                  </Accordion.Header>
                  <Accordion.Body>
                    <Table responsive style={{ fontSize: "12px" }}>
                      <thead>
                        <tr>
                          <th># Orden</th>
                          <th>Código</th>
                          <th>Nombre</th>
                          <th>Asistirán</th>
                        </tr>
                      </thead>
                      {invitadosQueAsistiranNovio.map((a) => (
                        <tbody key={a.Codigo}>
                          <td>{GetRight(a.Orden)}</td>
                          <td>{a.Codigo}</td>
                          <td>{a.ApellidosDeFamilia}</td>
                          <td>{a.NumeroDeInvitadosPorAsistir}</td>
                        </tbody>
                      ))}
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                No asistirán {TotalinvitadosQueNoAsistiranSum} personas (
                {TotalinvitadosQueNoAsistiran} invitaciones)
              </Accordion.Header>
              <Accordion.Body>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    Invitados novia: {TotalinvitadosQueNoAsistiranSumNovia}{" "}
                    personas ({TotalinvitadosQueNoAsistiranNovia} invitaciones)
                  </Accordion.Header>
                  <Accordion.Body>
                    {InvitadosQueNoAsistiranNovia.map((a) => (
                      <p
                        style={{
                          fontSize: "15px",
                          padding: "0px",
                          margin: "0px",
                        }}
                        key={a.Codigo}
                      >
                        {a.ApellidosDeFamilia}
                      </p>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    Invitados novio {TotalinvitadosQueNoAsistiranSumNovio}{" "}
                    personas ({TotalinvitadosQueNoAsistiranNovio} invitaciones)
                  </Accordion.Header>
                  <Accordion.Body>
                    {InvitadosQueNoAsistiranNovio.map((a) => (
                      <p
                        style={{
                          fontSize: "15px",
                          padding: "0px",
                          margin: "0px",
                        }}
                        key={a.Codigo}
                      >
                        {a.ApellidosDeFamilia}
                      </p>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="6">
              <Accordion.Header>
                Sin confirmar {TotalInvitadosSinConfirmar} personas (
                {TotalInvitacionesSinConfirmar} invitaciones)
              </Accordion.Header>
              <Accordion.Body>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>
                    Invitados novia: {TotalInvitadosSinConfirmarNovia} personas
                    ({TotalInvitacionesSinConfirmarNovia} invitaciones)
                  </Accordion.Header>
                  <Accordion.Body>
                    <Table responsive style={{ fontSize: "12px" }}>
                      <thead>
                        <tr>
                          <th># Orden</th>
                          <th>Código</th>
                          <th>Nombre</th>
                          <th># Invitados</th>
                        </tr>
                      </thead>
                      {InvitadosSinConfirmarNovia.map((a) => (
                        <tbody key={a.Codigo}>
                          <td>{GetRight(a.Orden)}</td>
                          <td>{a.Codigo}</td>
                          <td>{a.ApellidosDeFamilia}</td>
                          <td>{a.NumeroDeInvitados}</td>
                        </tbody>
                      ))}
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="8">
                  <Accordion.Header>
                    Invitados novio: {TotalInvitadosSinConfirmarNovio} personas
                    ({TotalInvitacionesSinConfirmarNovio} invitaciones)
                  </Accordion.Header>
                  <Accordion.Body>
                    <Table responsive style={{ fontSize: "12px" }}>
                      <thead>
                        <tr>
                          <th># Orden</th>
                          <th>Código</th>
                          <th>Nombre</th>
                          <th># Invitados</th>
                        </tr>
                      </thead>
                      {InvitadosSinConfirmarNovio.map((a) => (
                        <tbody key={a.Codigo}>
                          <td>{GetRight(a.Orden)}</td>
                          <td>{a.Codigo}</td>
                          <td>{a.ApellidosDeFamilia}</td>
                          <td>{a.NumeroDeInvitados}</td>
                        </tbody>
                      ))}
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </header>
    </div>
  );
};

export default Confirmados;
