import dayjs from 'dayjs';

export function getRemainingTimeUntilMsTimeStamp(timestampMs){
    const timestampDayjs = dayjs(timestampMs);
    const nowDayJs = dayjs();
if(timestampDayjs.isBefore(nowDayJs)){
    return {
        seconds: '00',
        minutes: '00',
        hours: '00',
        days: '00'
    }
}
return {
    seconds: getRemainingSeconds(nowDayJs, timestampDayjs),
    minutes:  getRemainingMinutes(nowDayJs, timestampDayjs),
    hours: getRemainingHours(nowDayJs, timestampDayjs),
    days:  getRemainingDays(nowDayJs, timestampDayjs)
};
}


function getRemainingSeconds(nowDayJs, timestampDayjs){
const seconds = timestampDayjs.diff(nowDayJs, 'seconds') % 60;
return padWithZeros(seconds, 2);
}

function getRemainingMinutes(nowDayJs, timestampDayjs){
    const minutes = timestampDayjs.diff(nowDayJs, 'minutes') % 60;
return padWithZeros(minutes, 2);
}

function getRemainingHours(nowDayJs, timestampDayjs){
    const hours = timestampDayjs.diff(nowDayJs, 'hours') % 24;
return padWithZeros(hours, 2);
}

function getRemainingDays(nowDayJs, timestampDayjs){
    const days = timestampDayjs.diff(nowDayJs, 'days');
return padWithZeros(days, 2);
}

function padWithZeros(number, minLenght){
    const numberString = number.toString();
    if (numberString.length  >= minLenght) return numberString;
    return "0".repeat(minLenght - numberString.length ) + numberString;
}