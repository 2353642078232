import "./../App.css";
import React, { useState } from "react";
import Parse from "parse/dist/parse.min.js";
import { Button, Col, Container, Form, Row, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import image3 from "./../Images/LogoBlanco.png";
import Informacion from "./Informacion";

const app_id = process.env.REACT_APP_PARSE_ID;
const javaScriptKey = process.env.REACT_APP_PARSE_JAVASCRIPT_KEY;
const hostUrl = process.env.REACT_APP_PARSE_HOST_URL;

Parse.initialize(app_id, javaScriptKey);
Parse.serverURL = hostUrl;

const VerificationForm = (props) => {
  const [invitationCode, setInvitationCode] = useState("");
  const [showMessage, setShow] = useState(false);
  const [message, setMessage] = useState("");

  const handleChange = (event) => {
    setInvitationCode(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    debugger;

    if(e.nativeEvent.submitter.id === 'Verificar'){
      const query = new Parse.Query("Guest");
      query.equalTo("Codigo", invitationCode);
  
      try {
        const queryResults = await query.find();
        
        if (queryResults.length > 0) {
          props.onSubmit(queryResults[0]);
        } else {
          setMessage(
            "El código introducido no es válido. Favor de revisarlo en su invitación o contactar a los novios."
          );
          setShow(true);
          props.onSubmit(null);
        }
      } catch (error) {
        setMessage(`Error! ${error.message}`);
      }
    }else{
       props.onSubmit(1);
    }
    
  };

  return (
    <Container fluid="md">
      <Form onSubmit={(e) => handleSubmit(e)}>
        <Row>
          <Col></Col>
          <Col xs={6}>
            <Row>
           
              <div className="text-white fst-italic text-center">
              <br/>
              <br/>
                <img src={image3} style={{ height: "auto", width: "90%" }} />
              </div>
           
              <div className="d-grid gap-2">
                <Form.Group>
                  <Form.Control
                    type="input"
                    id="codeField"
                    placeholder="Código de invitación"
                    onChange={handleChange}
                  ></Form.Control>
                </Form.Group>

                <Row>
                  {showMessage === true ? (
                    <Alert
                      variant="danger"
                      onClose={() => setShow(false)}
                      dismissible
                    >
                      <Alert.Heading>!Error!</Alert.Heading>
                      <p>{message}</p>
                    </Alert>
                  ) : null}
                </Row>
              </div>
              <div className="d-grid gap-2">
                <Button id='Verificar' variant="outline-light" size="lg" type="submit">
                  Verificar
                </Button>
                <Button id='MasInfo' variant="outline-light" size="lg" type="submit">
                  Más información
                </Button>
              </div>
            </Row>
          </Col>
          <Col />
        </Row>
      </Form>
    </Container>
  );
};

export default VerificationForm;
