import React, { useState } from "react";
import {Row } from "react-bootstrap";

const PaseDigital = ({ guest, guestCode }) => {
  const [model, setModel] = useState(false);
  const [temImgSrc, setTempImgsrc] = useState("");
  const getImg = (imgSource) => {
    setTempImgsrc(imgSource);
    setModel(true);
  };

  return (
    <div className="App2">
    <header className="App-header">
    
      <div className={model ? "model open" : "model"}>
        <img src={guest.Pase._url} onClick={() => setModel(false)} ></img>
        <p style={{color:"white",position:"fixed", top:"100px"}}>clic en la imagen para cerrar</p>
      </div>
      <div >
        <Row>
        <p style={{color:"white", textAlign:"center"}}>Este pase es único y no puede ser usado por nadie mas que la persona a la que le fue asignado:</p>
        <h1 style={{color:"white", textAlign:"center"}}>{guest.ApellidosDeFamilia}</h1>
        </Row>
        <Row>
        <img
          src={guest.Pase._url}
          style={{ width: "100%" }}
          onClick={() => getImg(guest.Pase._url)}
          ></img>
        </Row>
       
         
      </div>
    </header>
    </div>
  );
};

export default PaseDigital;
